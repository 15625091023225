import React from "react";
import { Link } from "gatsby";
import { FiPhone, FiSmartphone, FiMail } from "react-icons/fi";
import { IconFacebook, IconInstagram } from "./assets";

const Footer = ({ siteTitle }) => {
  return (
    <div>
      <div className="footer-wrapper">
        <div className="container is-widescreen">
          <footer>
            <div className="columns is-centered">
              <div className="column">
                <p className="has-text-centered">
                  <Link to="/">
                    <img
                      src={require("../images/logo-tectonic-landscapes-large.svg")}
                      alt={siteTitle}
                      className="tectonic-logo"
                    />
                  </Link>
                </p>

                <div className="columns">
                  <div className="column">
                    <p className="has-text-centered is-v-padded">
                      <FiPhone className="icon icon-position" size="2rem" />
                      01732 500507
                    </p>
                    <p className="has-text-centered is-v-padded">
                      <FiSmartphone
                        className="icon icon-position"
                        size="2rem"
                      />
                      07512 330350
                    </p>
                    <p className="has-text-centered is-v-padded">
                      <FiMail className="icon icon-position" size="2rem" />{" "}
                      info@tectonicltd.co.uk
                    </p>
                    <p className="has-text-centered is-v-padded">
                      <a href="https://www.facebook.com">
                        <IconFacebook fill="#4a4a4a" />
                      </a>
                      <a
                        className="social-icon"
                        href="https://www.instagram.com"
                      >
                        <IconInstagram fill="#4a4a4a" />
                      </a>
                    </p>
                    <p className="has-text-centered is-v-padded">
                      &copy; {new Date().getFullYear()} Tectonic Landscapes. All
                      rights reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Footer;
